import React from 'react'
import PropTypes from 'prop-types'
import { Input } from 'semantic-ui-react'
import Wrapper from "./Wrapper";


export const FormInputAdvanced = (props) => {
    return <Wrapper {...props} control={Input} />;
};

FormInputAdvanced.propTypes = {
    ...Wrapper.propTypes,
    ...Input.propTypes,
    error: PropTypes.string,
};

FormInputAdvanced.defaultProps = {
    type: 'text',
};

export default FormInputAdvanced;
