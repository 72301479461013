const getHandledProps = (Component, props) => {
    const { handledProps = [] } = Component;

    return Object.keys(props).reduce((acc, prop) => {
        if (handledProps.indexOf(prop) !== -1) acc[prop] = props[prop];
        return acc;
    }, {})
};

export default getHandledProps;
