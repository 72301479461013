import { Tab as SemanticTab } from "semantic-ui-react";

class Tab extends SemanticTab {}

Tab.propTypes = SemanticTab.propTypes;

Tab.defaultProps = {
    ...SemanticTab.defaultProps,
    menu:{
        secondary: true,
        pointing: true,
    }
};

export default Tab;
