import React from 'react';
import PropTypes from 'prop-types';
import { useTable } from 'react-table';
import TableTable from './table/Table';


function TableSimple(props) {
    const {
        ref,
        columns,
        data,
        rowProps,
        rowHeaderProps,
        tableProps,
    } = props;

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data,
    });

    // Render the UI for your table
    return (
        <TableTable
            forwardRef={ref}
            columns={columns}
            rows={rows}
            headerGroups={headerGroups}

            rowProps={rowProps}
            rowHeaderProps={rowHeaderProps}
            tableProps={tableProps}
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            prepareRow={prepareRow}

            showPagination={false}
        />
    )
}

TableSimple.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object),
    data: PropTypes.arrayOf(PropTypes.object),
};

export default TableSimple;
