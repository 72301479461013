import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
// import StepGroup from './StepGroup';
import CompletedIcon from './completed.svg'


class Step extends React.Component {
    // static Group = StepGroup; // TODO хз как побороть циклическую зависимость, можно посотреть semantic-ui-react компонент Step, там все ок

    render() {
        const classes = cx(
            'mui step',
            {
                'completed': this.props.completed,
                'active': this.props.active,
                'disabled': this.props.disabled,
            }
        );

        return (
            <div className={classes}>
                <div className={'highliner'}>
                    <div className={'circle'}/>
                    {this.props.completed && <CompletedIcon/>}
                </div>
                <div className={'title'}>{this.props.title}</div>
            </div>
        );
    }
}

Step.propTypes = {
    title: PropTypes.string,
    active: PropTypes.bool,
    completed: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default Step;
