import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Menu, Sidebar } from 'semantic-ui-react';
import cx from 'classnames';
import { invoke } from 'lodash';


class MenuItemCustom extends Menu.Item {
    handleClick = (e) => {
        invoke(this.props, 'onClick', e, this.props);
    }
}

class SidebarMenu extends React.Component {
    state = {};

    constructor(props) {
        super(props);

        const { storage, storageKeyName } = this.props;

        if (storage) {
            const state = JSON.parse(window.localStorage.getItem(storageKeyName));
            if (state && typeof state === 'object') {
                this.state = state;
            }
        }

        this.menuItem = props.customClickHandler ? MenuItemCustom : Menu.Item;
    }

    disableNavigate = (e) => {
        console.log('>>>', e);
        e.preventDefault();
    };

    handlerGroupClick = (e) => {
        e.preventDefault();
        const name = e.currentTarget.dataset.name;

        this.setState(
            (state) => ({ [name]: !state[name] }),
            () => {
                const { storage, storageKeyName } = this.props;

                if (storage) {
                    window.localStorage.setItem(storageKeyName, JSON.stringify(this.state));
                }
            }
        );
    };

    render() {
        const { menu, className } = this.props;
        const MenuItem = this.menuItem;

        return (
            <Sidebar
                as={Menu}
                visible
                animation={'overlay'}
                vertical
                borderless
                className={cx('sidebar-menu', className)}
            >
                {menu.map((group, i) => {
                    const { name, title, disabled, icon = 'angle right', items, ...rest } = group;
                    const hasItems = Array.isArray(items);
                    const groupProps = {
                        'data-name': name,
                        'aria-expanded': hasItems,
                        'aria-disabled': disabled ? 'true' : 'false',
                    };

                    const groupHandler = disabled
                        ? undefined
                        : hasItems
                            ? this.handlerGroupClick
                            : undefined;

                    return (
                        <Menu.Item key={name} disabled={disabled} className={cx({ 'visible': !this.state[name] })}>
                            <div {...groupProps} onClick={groupHandler} className={'group'}>
                                <Icon name={icon} className={'left'}/>
                                {hasItems ? (
                                    <React.Fragment>
                                        <Menu.Header as={'span'}>{title}</Menu.Header>
                                        <Icon name={'angle down'} className={'right'}/>
                                    </React.Fragment>
                                ) : (
                                    <MenuItem
                                        name={name}
                                        disabled={disabled}
                                        onClick={disabled ? this.disableNavigate : undefined}
                                        {...rest}
                                    >
                                        {title}
                                    </MenuItem>
                                )}
                            </div>

                            {hasItems && (
                                <Menu.Menu className={'transition'}>
                                    {group.items.map((item) => {
                                        const { title, disabled, ...rest } = item;

                                        return (
                                            <MenuItem
                                                key={item.name}
                                                disabled={disabled}
                                                onClick={disabled ? this.disableNavigate : undefined}
                                                {...rest}
                                            >
                                                {title}
                                            </MenuItem>
                                        )
                                    })}
                                </Menu.Menu>
                            )}
                        </Menu.Item>
                    );
                })}
            </Sidebar>
        )
    }
}

SidebarMenu.defaultProps = {
    storage: true,
    storageKeyName: 'sidebar_menu_state',
    customClickHandler: true,
};

SidebarMenu.propTypes = {
    className: PropTypes.string,
    storage: PropTypes.bool,
    storageKeyName: PropTypes.string,
    customClickHandler: PropTypes.bool,
    menu: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            icon: PropTypes.string,
            url: PropTypes.string,
            disabled: PropTypes.bool,
            items: PropTypes.arrayOf(PropTypes.shape({
                name: PropTypes.string.isRequired,
                title: PropTypes.string.isRequired,
                disabled: PropTypes.bool,
            })),
        })
    ).isRequired,
};

export default SidebarMenu;
