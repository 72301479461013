import React from 'react'
import PropTypes from 'prop-types'
import { FormField as SemanticFormField } from 'semantic-ui-react'
import getUnhandledProps from '../../lib/getUnhandledProps';
import * as childrenUtils from 'semantic-ui-react/dist/es/lib/childrenUtils'
import { HTML_UNICODE_NBSP } from './constants';


function renderHint(hint) {
    if (hint === null || hint === undefined || hint === false) {
        return null;
    }

    return <span className='hint'>{hint}</span>;
}

const ControlWrapper = (props) => {
    const {
        children,
        content,
        disabled,
        type,
        elem,
        hint,
        ...rest
    } = props;

    // noinspection JSCheckFunctionSignatures
    const controlRest = getUnhandledProps(SemanticFormField, rest);
    const controlProps = {...controlRest, children, content, disabled, type};
    const Element = elem;

    return (
        <React.Fragment>
            {<Element {...controlProps}/>}
            {renderHint(hint)}
        </React.Fragment>
    )
};

const FormFieldWrapper = (props) => {
    const {
        children,
        type,
        control,
        error,
        hint,
        ...rest
    } = props;

    let message;
    if (error !== undefined) {
        message = error;
    } else if (hint !== undefined) {
        message = hint;
    }

    if (childrenUtils.isNil(children)) {
        return <SemanticFormField
            {...rest}
            error={!!error}
            type={type}
            elem={control}
            hint={message}
            control={ControlWrapper}
        />;
    }

    return (
        <SemanticFormField error={!!error} {...rest}>
            {children}
            {renderHint(message)}
        </SemanticFormField>
    )
};

FormFieldWrapper.propTypes = {
    ...SemanticFormField.propTypes,
    error: PropTypes.string,
    hint: PropTypes.string,
};

FormFieldWrapper.defaultProps = {
    ...SemanticFormField.defaultProps,
    hint: HTML_UNICODE_NBSP,
};

FormFieldWrapper.handledProps = SemanticFormField.handledProps;

export default FormFieldWrapper;
