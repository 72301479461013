import React from 'react';
import { Icon, Message } from 'semantic-ui-react';
import cx from 'classnames';

const getToastIcon = (appearance) => {
    switch (appearance) {
        case 'error':
            return 'close circle';
        case 'warning':
            return 'exclamation circle';
        default:
            return 'check circle outline';
    }
}

function Toast(props) {
    let { className, children, content, onDismiss, appearance, ...rest } = props;
    className = cx(className, 'toast', `toast-${appearance}`);

    if (React.Children.count(children) === 0) {
        content = [content, <Icon name={'check circle outline'}/>];

        return <Message {...rest} className={className} content={content}/>
    }

    return (
        <Message className={className} {...rest}>
            {children}
            <Icon name={getToastIcon(appearance)} />
        </Message>
    );
}

Toast.Content = Message.Content;
Toast.Header = Message.Header;
Toast.List = Message.List;
Toast.Item = Message.Item;

export default Toast;