import React from 'react'
import TableSimple from './TableSimple';
import TableAsync from './TableAsync';
import PropTypes from 'prop-types';


class Table extends React.Component {
    static Simple = TableSimple;

    static Async = TableAsync;

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageCount: 0,
            isFetching: true,
            fetcher: this.createFetchData(),
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.fetchData !== prevProps.fetchData) {
            this.setState({ fetcher: this.createFetchData() });
        }
    }

    createFetchData = () => {
        return (options) => {
            this.setState({ isFetching: true });

            const promise = new Promise(resolve => resolve(this.props.fetchData(options)));

            return promise
                .then(res => this.setState({
                    data: res.data,
                    pageCount: res.pageCount,
                    isFetching: false,
                }));
        };
    };

    render() {
        const { data, fetcher, isFetching, pageCount } = this.state;
        const { columns, filter, pageSizes, defaultPageSize, isScrollable, selectable = true, showUpperPagination, queryPaginationOff = false } = this.props;

        return (
            <TableAsync
                columns={columns}
                data={data}
                filter={filter}
                fetchData={fetcher}
                isFetching={isFetching}
                pageSizes={pageSizes}
                defaultPageSize={defaultPageSize}
                pageCount={pageCount}
                isScrollable={isScrollable}
                selectable={selectable}
                showUpperPagination={showUpperPagination}
                queryPaginationOff={queryPaginationOff}
            />
        )
    }
}

Table.defaultProps = {
    defaultPageSize: 10,
};

Table.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object),
    filter: PropTypes.arrayOf(PropTypes.object),
    fetchData: PropTypes.func.isRequired,
    pageSizes: PropTypes.arrayOf(PropTypes.number),
    defaultPageSize: PropTypes.number,
    isScrollable: PropTypes.bool,
    selectable: PropTypes.bool,
    showUpperPagination: PropTypes.bool,
    queryPaginationOff: PropTypes.bool,
};

export default Table;
