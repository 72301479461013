import FormFieldWrapper from './FormFieldWrapper';
import FormDropdownWrapper from './FormDropdownWrapper'
import FormInput from './FormInputWrapper';
import FormInputAdvanced from './FormInputAdvanced'
import Wrapper from './Wrapper'
import { Form as SemanticForm } from 'semantic-ui-react';

class Form extends SemanticForm {
    static Dropdown = FormDropdownWrapper;
    static Field = FormFieldWrapper;
    static FieldAdvanced = Wrapper;
    static Input = FormInput;
    static InputAdvanced = FormInputAdvanced;
}

export default Form;
