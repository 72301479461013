import { Dropdown, Pagination } from "semantic-ui-react";
import React, { useCallback } from "react";
import Backward from "../assets/backward.svg";
import Forward from "../assets/forward.svg";
import PropTypes from 'prop-types';

function TableFooter(props) {
    const {
        showPagination,
        pageIndex,
        pageCount,
        defaultPageSize,
        pageSizeOptions,
        setPageIndex,
        setPageSize,
    } = props;

    const setPageSizeWrapper = useCallback(
        (e, { value }) => setPageSize(Number(value)),
        [ setPageSize ]
    );

    if (!showPagination) {
        return;
    }

    return (
        <div className="table-pagination-container">
            {pageCount > 1 && (
                <Pagination
                    activePage={pageIndex + 1}
                    onPageChange={setPageIndex}
                    totalPages={pageCount}
                    prevItem={{ content: <Backward className="icon-clickable" />, icon: true }}
                    nextItem={{ content: <Forward className="icon-clickable" />, icon: true }}
                    firstItem={null}
                    lastItem={null}
                />
            )}
            <div className="table-footer-whitespace" />
            {pageSizeOptions && (
                <div className="table-page-sizes">
                    <Dropdown
                        className="table-page-sizes-dropdown"
                        selection
                        compact
                        options={pageSizeOptions}
                        defaultValue={defaultPageSize}
                        onChange={setPageSizeWrapper}
                    />
                </div>
            )}
        </div>
    );
}


TableFooter.propTypes = {
    showPagination: PropTypes.bool,
    pageIndex: PropTypes.number,
    pageCount: PropTypes.number,
    defaultPageSize: PropTypes.number,
    pageSizeOptions: PropTypes.array,
    setPageSize: PropTypes.func,
    setPageIndex: PropTypes.func,
};

export default React.memo(TableFooter);