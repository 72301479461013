import React from 'react'
import PropTypes from 'prop-types'
import { Dropdown } from "semantic-ui-react";

import FormField from './FormFieldWrapper'
import Wrapper from "./Wrapper";


const FormDropdownWrapper = (props) => {
    return <Wrapper {...props} control={Dropdown} />;
};

FormDropdownWrapper.propTypes = {
    ...FormField.propTypes,
    ...Dropdown.propTypes,
    error: PropTypes.string,
    help: PropTypes.string,
};

FormDropdownWrapper.defaultProps = {
    selection: true
};

export default FormDropdownWrapper;
