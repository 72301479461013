import React from 'react';
import { FormInput as SemanticFormInput } from 'semantic-ui-react';
import FormFieldWrapper from './FormFieldWrapper';


function FormInputWrapper(props) {
    return <SemanticFormInput {...props} />
}

FormInputWrapper.propTypes = SemanticFormInput.propTypes;
FormInputWrapper.defaultProps = {
    ...SemanticFormInput.defaultProps,
    as: FormFieldWrapper,
};

export default FormInputWrapper;
