import React from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from "prop-types";

function TableHeader(props) {
    const {
        columns,
        headerGroups,
        rowHeaderProps,
    } = props;

    return (
        <Table.Header>
            {headerGroups.map((headerGroup, i) => {
                const props = rowHeaderProps ? rowHeaderProps(headerGroup) : undefined;

                return (
                    <Table.Row {...headerGroup.getHeaderGroupProps(props)} key={i}>
                        {headerGroup.headers.map((column, index) => {
                            const props = columns[index].cellHeaderProps ? columns[index].cellHeaderProps(column) : undefined;
                            const style = { minWidth: columns[index].minWidth, width: columns[index].width, maxWidth: columns[index].maxWidth }

                            return (
                                <Table.HeaderCell {...column.getHeaderProps(props)} key={index} style={style}>
                                    {column.render('Header')}
                                </Table.HeaderCell>
                            )
                        })}
                    </Table.Row>
                )
            })}
        </Table.Header>
    )
}

TableHeader.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object),
    headerGroups: PropTypes.array,
    rowHeaderProps: PropTypes.func,
};

export default React.memo(TableHeader);
