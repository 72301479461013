import { Table } from 'semantic-ui-react';
import React from 'react';
import TableHeader from './Header';
import TableBody from './Body';
import TableFooter from './Footer';
import cx from 'classnames';
import PropTypes from 'prop-types';

function TableTable(props) {
    const {
        ref,
        columns,
        rows,
        headerGroups,

        rowProps,
        rowHeaderProps,
        tableProps,
        getTableProps,
        getTableBodyProps,
        prepareRow,

        showPagination,
        showUpperPagination,
        pageIndex,
        pageCount,
        defaultPageSize,
        pageSizeOptions,
        setPageIndex,
        setPageSize,
        isScrollable,

        selectable,
    } = props;

    const canShowUpperPag = showUpperPagination && pageCount > 1;

    return (
        <>
            <div className={cx(isScrollable && 'table-scroll-box')}>
                {canShowUpperPag && <div className='upper-pagination'>
                    <TableFooter
                        showPagination={showPagination}
                        pageIndex={pageIndex}
                        pageCount={pageCount}
                        setPageIndex={setPageIndex}
                        setPageSize={setPageSize}
                    />
                </div>}
                <Table celled {...getTableProps(tableProps)} ref={ref} selectable={selectable}>
                    <TableHeader
                        columns={columns}
                        headerGroups={headerGroups}
                        rowHeaderProps={rowHeaderProps}
                    />
                    <TableBody
                        columns={columns}
                        rows={rows}
                        rowProps={rowProps}
                        getTableBodyProps={getTableBodyProps}
                        prepareRow={prepareRow}
                    />
                </Table>
            </div>
            <TableFooter
                showPagination={showPagination}
                pageIndex={pageIndex}
                pageCount={pageCount}
                defaultPageSize={defaultPageSize}
                pageSizeOptions={pageSizeOptions}
                setPageIndex={setPageIndex}
                setPageSize={setPageSize}
            />
        </>
    )
}

TableTable.propTypes = {
    ref: PropTypes.object,
    columns: PropTypes.arrayOf(PropTypes.object),
    rows: PropTypes.array,
    headerGroups: PropTypes.array,

    rowProps: PropTypes.object,
    rowHeaderProps: PropTypes.object,
    tableProps: PropTypes.object,
    getTableProps: PropTypes.func,
    getTableBodyProps: PropTypes.func,
    prepareRow: PropTypes.func,

    showPagination: PropTypes.bool,
    showUpperPagination: PropTypes.bool,
    pageIndex: PropTypes.number,
    pageCount: PropTypes.number,
    defaultPageSize: PropTypes.number,
    pageSizeOptions: PropTypes.array,
    setPageIndex: PropTypes.func,
    setPageSize: PropTypes.func,
    isScrollable: PropTypes.bool,

    selectable: PropTypes.bool,
};

export default TableTable;
