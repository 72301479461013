import React from 'react'
import PropTypes from 'prop-types'
import Step from './Step'
import * as childrenUtils from 'semantic-ui-react/dist/es/lib/childrenUtils'


class StepGroup extends React.Component {
    render() {
        if (childrenUtils.isNil(this.props.children)) {
            return (
                <div className={'mui steps'}>
                    {this.props.items.map(v => {
                        return (
                            <Step
                                title={v.title}
                                active={v.active}
                                completed={v.completed}
                                disabled={v.disabled}
                            />
                        )
                    })}
                </div>
            )
        }

        return (
            <div className={'mui steps'}>
                {this.props.children}
            </div>
        );
    }
}

StepGroup.defaultProps = {
    items: [],
};

StepGroup.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            icon: PropTypes.string,
            title: PropTypes.string,
            active: PropTypes.bool,
            completed: PropTypes.bool,
            disabled: PropTypes.bool,
        })
    )
};

export default StepGroup;
