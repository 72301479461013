import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';


function LinkControl({ to, content }) {
    return (
        <Link to={to} className={'link-control'}>
            <Icon name={'mts-icon-clone'}/>
            {content}
        </Link>
    )
}

LinkControl.propTypes = {
    to: PropTypes.string.isRequired,
    content: PropTypes.node.isRequired,
}

export default LinkControl;
