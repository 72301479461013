import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { usePagination, useTable, useFilters } from 'react-table';
import { Dimmer, Loader, Ref } from 'semantic-ui-react';
import TableTable from './table/Table';
import SearchIcon from './assets/search.svg';
import { useLocation, useHistory } from "react-router-dom";
import isEqual from 'lodash/isEqual'

const DEFAULT_PAGE_INDEX = 0;
const DEFAULT_PAGE_SIZE = 30;

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [ value ]);
    return ref.current;
  }

const scrollToRef = (ref) => {
    try {
        if (window.scrollTo && ref.current && ref.current.getBoundingClientRect) {
            window.scrollTo(0, ref.current.getBoundingClientRect().top + window.scrollY - 20);
        }
    } catch (e) {
        console.error(e);
    }
};

function TableAsync(props) {
    const {
        columns,
        data,
        filter,
        fetchData,
        isFetching,
        pageCount,
        pageSizes,
        defaultPageIndex = DEFAULT_PAGE_INDEX,
        defaultPageSize = DEFAULT_PAGE_SIZE,
        scroll,
        rowProps,
        rowHeaderProps,
        tableProps,
        ref,
        isScrollable,
        selectable,
        showUpperPagination,
        queryPaginationOff
    } = props;

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const history = useHistory();

    //------------------------------------------------------------------------------------------------------------------

    const prevFilter = usePrevious(filter);

    //------------------------------------------------------------------------------------------------------------------

    const scrollRef = useRef(null);

    //------------------------------------------------------------------------------------------------------------------

    const pageSizeOptions = useMemo(() => {
        if (false === Array.isArray(pageSizes)) {
            return undefined;
        }

        return pageSizes.map(v => ({
            text: v,
            value: v,
            key: v,
        }));
    }, [ pageSizes ]);

    const pageIndexQuery = searchParams.get("page") ? Number(searchParams.get("page")) - 1 : undefined;
    const pageSizeQuery = searchParams.get("size") ? Number(searchParams.get("size")) : undefined;

    const computedPageIndex = pageIndexQuery || defaultPageIndex;
    const computedPageSize = pageSizeQuery || defaultPageSize || (pageSizeOptions && pageSizeOptions[0].value);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        gotoPage,
        setPageSize,
        setAllFilters,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: computedPageIndex, pageSize: computedPageSize },
            manualPagination: true,
            manualFilters: true,
            pageCount,
        },
        useFilters,
        usePagination,
    );

    //------------------------------------------------------------------------------------------------------------------

    const [ canScroll, setCanScroll ] = useState(false);

    //------------------------------------------------------------------------------------------------------------------

    const setPageIndex = useCallback((e, { activePage }) => {
        if (false === canScroll) {
            setCanScroll(true);
        }
        const newIndex = Number(activePage) - 1;

        gotoPage(newIndex);
    }, [ gotoPage, canScroll ]);

    //------------------------------------------------------------------------------------------------------------------

    const setQueryParams = (toPageIndex, toPageSize) => {
        searchParams.set("page", toPageIndex + 1);
        searchParams.set("size", toPageSize);
        history.push({ search: searchParams.toString() });
    };
    
    //------------------------------------------------------------------------------------------------------------------

    useEffect(() => {
        setPageIndex({}, { activePage: 1 });
    }, [ fetchData ]);
    
    useEffect(() => {
        fetchData && fetchData({ pageIndex, pageSize });
    }, [ fetchData, pageIndex, pageSize ]);

    useEffect(() => {
        if (filter) {
            setAllFilters(Object.keys(filter).map(key => ({ id: key, value: filter[key] })));
        } else {
            setAllFilters([]);
        }

        if (!isEqual(prevFilter, filter)) {
            setPageIndex({}, { activePage: 1 })
            setQueryParams(computedPageIndex, pageSize);
        }
    }, [ setAllFilters, filter, prevFilter, pageSize ]);

    useEffect(() => {
        canScroll && scroll && (scroll === true ? scrollToRef(scrollRef) : scroll(scrollRef));
    }, [ data, scroll, canScroll ]);

    useEffect(() => {
        if (!queryPaginationOff) {
            setQueryParams(pageIndex, pageSize);
        }
    }, [ pageIndex, pageSize, queryPaginationOff ]);

    //------------------------------------------------------------------------------------------------------------------

    if (!isFetching && data.length === 0) {
        return (
            <Ref innerRef={scrollRef}>
                <div className="table-no-data">
                    <SearchIcon />
                    <span>Ничего не найдено</span>
                </div>
            </Ref>
        )
    }

    return (
        <Ref innerRef={scrollRef}>
            <Dimmer.Dimmable className='table-async-container'>
                <Dimmer active={isFetching} inverted>
                    <Loader active={isFetching} />
                </Dimmer>

                <TableTable
                    forwardRef={ref}
                    columns={columns}
                    rows={rows}
                    headerGroups={headerGroups}

                    rowProps={rowProps}
                    rowHeaderProps={rowHeaderProps}
                    tableProps={tableProps}
                    getTableProps={getTableProps}
                    getTableBodyProps={getTableBodyProps}
                    prepareRow={prepareRow}

                    showPagination={true}
                    showUpperPagination={showUpperPagination}
                    pageIndex={pageIndex}
                    pageCount={pageCount}
                    defaultPageSize={computedPageSize}
                    pageSizeOptions={pageSizeOptions}
                    setPageIndex={setPageIndex}
                    setPageSize={setPageSize}
                    isScrollable={isScrollable}

                    selectable={selectable}
                />
            </Dimmer.Dimmable>
        </Ref>
    )
}

TableAsync.defaultProps = {
    scroll: true,
};

TableAsync.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object),
    data: PropTypes.arrayOf(PropTypes.object),
    filter: PropTypes.arrayOf(PropTypes.object),
    fetchData: PropTypes.func.isRequired,
    isFetching: PropTypes.bool,
    pageCount: PropTypes.number,
    pageSizes: PropTypes.arrayOf(PropTypes.number),
    defaultPageIndex: PropTypes.number,
    defaultPageSize: PropTypes.number,
    rowProps: PropTypes.object,
    rowHeaderProps: PropTypes.object,
    tableProps: PropTypes.object,
    ref: PropTypes.object,
    scroll: PropTypes.oneOfType([ PropTypes.bool, PropTypes.func ]),
    isScrollable: PropTypes.bool,
    selectable: PropTypes.bool,
    showUpperPagination: PropTypes.bool,
    queryPaginationOff: PropTypes.bool,
};

export default TableAsync;
