import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Accordion, Icon } from 'semantic-ui-react';


const Hider = (props) => {
    const panels = useMemo(() => {
        return [
            {
                title: (
                    <Accordion.Title>
                        <div className={'title-and-icon'}>
                            <Icon name={'mts-icon-angle-down'} />
                            {props.title}
                        </div>
                    </Accordion.Title>
                ),
                content: {
                    content: props.children
                },
            }
        ];
    }, [props.title, props.children]);

    return <Accordion className={'hider'} panels={panels}/>;
};

Hider.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
};

export default Hider;