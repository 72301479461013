import React from 'react'
import PropTypes from 'prop-types'
import FormFieldWrapper from "./FormFieldWrapper";
import getHandledProps from "../../lib/getHandledProps";
import getUnhandledProps from '../../lib/getUnhandledProps';
import * as childrenUtils from 'semantic-ui-react/dist/es/lib/childrenUtils'
import { HTML_UNICODE_NBSP } from './constants';


// TODO костыль, т.к. "babel-plugin-transform-react-handled-props" умеет читать только файлы
const handleProps = FormFieldWrapper.handledProps || [];

const fakeComponent = {};
fakeComponent.handledProps = [ ...Object.keys(FormFieldWrapper.propTypes), ...handleProps ];

const Wrapper = (props) => {
    const {
        children,
        control,
        controlProps,
        label,
        error,
        hint,
        ...rest
    } = props;

    let message;
    if (error !== undefined) {
        message = error;
    } else if (hint !== undefined) {
        message = hint;
    }

    const restField = getHandledProps(fakeComponent, { ...rest, control, error, hint: message });
    const { content, disabled, type } = props;
    const restElement = {
        ...getUnhandledProps(fakeComponent, rest),
        content,
        disabled,
        type
    };

    if (false === childrenUtils.isNil(children)) {
        return (
            <FormFieldWrapper {...restField} {...restElement}>
                {label && <label>{label}</label>}
                {children}
            </FormFieldWrapper>
        );
    }

    const Element = control;

    return (
        <FormFieldWrapper {...restField}>
            {label && <label>{label}</label>}
            <Element {...restElement} {...controlProps} />
        </FormFieldWrapper>
    );
};

Wrapper.defaultProps = {
    hint: HTML_UNICODE_NBSP,
};

Wrapper.propTypes = {
    ...FormFieldWrapper.propTypes,
    controlProps: PropTypes.object,
    error: PropTypes.string,
    hint: PropTypes.string,
};

export default Wrapper;
