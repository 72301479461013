import React from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';

function TableBody(props) {
    const {
        columns,
        rows,
        rowProps,
        getTableBodyProps,
        prepareRow,
    } = props;

    return (
        <Table.Body {...getTableBodyProps()}>
            {rows.map((row, idx) => {
                prepareRow(row);

                const props = rowProps ? rowProps(row) : undefined;

                return (
                    <Table.Row key={idx} {...row.getRowProps(props)}>
                        {
                            row.cells.map((cell, index) => {
                                const props = columns[index].cellProps ? columns[index].cellProps(cell) : undefined;
                                return (
                                    <Table.Cell key={index} {...cell.getCellProps(props)}>
                                        {cell.render('Cell')}
                                    </Table.Cell>
                                )
                            })
                        }
                    </Table.Row>
                )
            })}
        </Table.Body>
    )
}

TableBody.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object),
    rows: PropTypes.array,
    rowProps: PropTypes.object,
    getTableBodyProps: PropTypes.func,
    prepareRow: PropTypes.func,
};

export default React.memo(TableBody);
