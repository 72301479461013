import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'semantic-ui-react';
import * as childrenUtils from 'semantic-ui-react/dist/es/lib/childrenUtils'


function LinkContainer(props) {
    const { as, children, icon, ...rest } = props;

    if (childrenUtils.isNil(children)) {
        return <Button {...rest} />;
    }

    return (
        <Button as={as} {...rest}>
            {props.children}
        </Button>
    )
}

LinkContainer.defaultProps = {
    as: 'a',
};

LinkContainer.propTypes = {
    as: PropTypes.oneOf([PropTypes.string, PropTypes.element]),
    icon: PropTypes.oneOf([PropTypes.string, PropTypes.element])
};

export default LinkContainer;